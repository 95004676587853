<template>
  <v-card elevation="0" class="col-md-12">
    <v-flex md12>
      <v-row justify="center" style="padding: 10px 10px 0 10px " class="col-md-12">
        <v-col cols="1" align-self="center">
          <v-row justify="end" v-if="questions.length > 0">
            <v-btn :disabled="step === 0 " color="gray" rounded text @click="step--" style="position:absolute; top: 120px">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="10" align-self="stretch" class="flex-grow-1 flex-shrink-0">
          <div id="start-view">
            <v-window v-model="startingWindow" v-if="!startingWindow">
              <v-window-item>
                <v-col md9>
                  <v-row>
                    <v-card-text class="pa-5 mb-1 text-center font-semibold" v-if="!examFinished && examDetails != null" >
                      <h5>{{ $t("_exam_list.Exam_text1") }} {{examDetails[0].Title}}. {{ $t("_exam_list.Exam_text2") }}
                      {{fancyTime(parseInt(examDetails[0].Time))}}.
                      {{ $t("_exam_list.Exam_text3") }}</h5>
                    </v-card-text>
                    <v-card-text class= "font-semibold" v-if="examFinished">
                      <h5>{{ $t("_exam_list.Exam_text4") }} {{examDetails[0].Title}}. {{ $t("_exam_list.Exam_text5") }}<br/><br/>
                        {{ $t("_exam_list.Exam_text7") }}</h5>
                    </v-card-text>
                  </v-row>

                </v-col>

                <v-flex>
                  <v-card-actions v-if="!examFinished">
                    <v-row justify="end">
                      <v-btn class= "font-semibold" color="light-green" depressed rounded @click="init"><h6>{{ $t("_common.Start") }}</h6> </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-flex>

              </v-window-item>
            </v-window>
          </div>

          <div id="exam-view" v-if="startingWindow" class="flex-grow">
            <!-- TABS 1: QUESTION NUMBER -->
            <v-tabs
              v-model="step"
              color="primary"
              show-arrows
              centered
              hide-slider
              @change="timekeeper(step)"
            >
              <v-tab v-for="(question, index) in questions" :key="`tab-${examId}-${index}`">
                <v-avatar
                  v-if="step === index"
                  color="primary lighten-1"
                  class="subheading white--text"
                  background-color="white"
                  size="24"
                  v-text="index+1"
                ></v-avatar>
                <v-avatar
                  v-else-if="typeof radioButtonModel[index] === 'number' || typeof radioButtonModel[index] === 'string'"
                  color="blue-grey lighten-1"
                  class="subheading white--text"
                  background-color="white"
                  size="24"
                  v-text="index+1"
                ></v-avatar>
                <v-avatar
                  v-else-if="typeof radioButtonModel[index] === 'object' && radioButtonModel[index].length === question.CorrectAnswerCount"
                  color="light-green lighten-1"
                  class="subheading white--text"
                  background-color="white"
                  size="24"
                  v-text="index+1"
                ></v-avatar>
                <v-avatar
                  v-else-if="typeof radioButtonModel[index] === 'object' && radioButtonModel[index].length < question.CorrectAnswerCount && radioButtonModel[index].length !== 0"
                  color="yellow darken-2"
                  class="subheading white--text"
                  background-color="white"
                  size="24"
                  v-text="index+1"
                ></v-avatar>
                <v-avatar
                  v-else
                  color="primary lighten-3"
                  class="subheading white--text"
                  background-color="white"
                  size="24"
                  v-text="index+1"
                ></v-avatar>
              </v-tab>
              <v-tab :key="`tab-gi${examId}-${maxStep+1}`">
                <v-avatar
                  v-if="questions.length > 0 && step === maxStep -1"
                  color="grey"
                  class="subheading white--text"
                  background-color="white"
                  size="24"
                  v-text="examEndIcon"
                ></v-avatar>
                <v-avatar
                  v-else-if="questions.length > 0"
                  color="grey lighten-3"
                  class="subheading white--text"
                  background-color="white"
                  size="24"
                  v-text="examEndIcon"
                ></v-avatar>
              </v-tab>
            </v-tabs>
            <!-- CARD TITLE -->
            <v-card-title v-if="questions.length > 0 && step !== maxStep -1"
                          class="title font-weight-regular justify-space-between">
              <span>{{ $t("_add_questions.Question") }} {{ step + 1 }}</span>
              <v-chip color="primary lighten-1">
                <span class="white--text ">{{fancyTimeFormat}}</span>
              </v-chip>
            </v-card-title>

            <v-window v-model="step">
              <!-- MAIN WINDOW LOOP -->
              <template v-for="(item, index) in questions">
                <v-window-item :key="`window-${examId}-${index}`" :value="index">
                  <v-card-text>
                    <!-- QUESTION TEXT -->
                     <img height="300"  v-if="item.ImageUrl" :src="item.ImageUrl" style= "margin:0 auto; display: block;margin-botom: 1rem">
                    <blockquote class="blockquote"><span v-on:oncopy="copyevent" v-html="item.Text"></span></blockquote>
                     
                    <!-- CHOICES -->
                    <template>
                      <v-container fluid v-if="item.Type == $goc.ENUMS.QUESTION_TYPE.OpenEnded">
                        <p>{{ answerRadios }}</p>
                        <ckeditor
                          :editor="editor"
                          :config="editorConfig"
                          v-model="radioButtonModel[step]"
                        ></ckeditor>
                      </v-container>
                      <v-container fluid v-else-if="item.Type == $goc.ENUMS.QUESTION_TYPE.MultipleChoice">
                        <p>{{ answerRadios }}</p>
                        <v-radio-group v-model="radioButtonModel[step]" :mandatory="false">
                          <div v-for="(choice,id) in item.Choices" :key="id" class="imageradio" :class="{ withIamge: !!choice.ImageUrl }">
                            <v-radio
                              color="orange darken-3"
                              :label="getChoice(choice.Text)" 
                              :value="id" >
                            </v-radio>
                            <img width="100"  v-if="choice.ImageUrl" :src="choice.ImageUrl">
                          </div>
                        </v-radio-group>
                      </v-container>
                      
                      <v-container fluid v-else-if="item.Type == $goc.ENUMS.QUESTION_TYPE.TrueFalse">
                        <p>{{ answerRadios }}</p>
                        <v-radio-group v-model="radioButtonModel[step]" :mandatory="false">
                          <v-radio
                            color="orange darken-3"
                            v-for="(choice,id) in item.Choices"
                            :key="id"
                            :label="getChoice(choice.Text)" 
                            :value="id" >
                          </v-radio>
                        </v-radio-group>
                      </v-container>
                      <v-container fluid v-else-if="item.Type == $goc.ENUMS.QUESTION_TYPE.Matching">
                        {{initMatchingChoice(step,index)}}
                        <p>{{ answerRadios }}</p>
                        <v-col
                          class="grey lighten-5 p-0 pl-5 pr-4"
                          id="answerList"
                          v-for="(answer,index2) in radioButtonModel[step]"
                          :key="index2">
                          <v-row align="center">
                            <v-select
                              class="ma-2"
                              v-model="radioButtonModel[step][index2].Key"
                              :items="item['MatchingKeys']"
                              item-value="item.text"
                              >
                              <template v-slot:item="{ item }">
                                <div class="d-flex justify-space-between">
                                    <span v-if="item.text">{{ item.text }}</span>
                                    <img width="100"  v-if="item.img && checkURL(item.img)" :src="item.img">
                                </div>
                               </template>
                            </v-select>
                            <v-select
                              class="ma-2"
                              v-model="radioButtonModel[step][index2].Value"
                              :items="item['MatchingValues']"
                              item-value="item.text"
                              >
                              <template v-slot:item="{ item }">
                                <div class="d-flex justify-space-between">
                                <span v-if="item.text">{{ item.text }}</span>
                                <img width="100" v-if="item.img && checkURL(item.img)" :src="item.img">
                                </div>
                             </template>
                            </v-select>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click="deleteAnswer(answer,step)" small color="error" dark fab>
                                  <v-icon>mdi-minus</v-icon>
                                </v-btn>
                              </template>
                              <span>Esc</span>
                            </v-tooltip>
                          </v-row>
                        </v-col>
                        <v-card-text style="position: relative"
                                     v-if="(radioButtonModel[index] != undefined && radioButtonModel[index].length < item.CorrectAnswerCount)">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                absolute
                                dark
                                fab
                                top
                                small
                                right
                                color="success"
                                @click="addAnswerInput(step,item.Type,item.CorrectAnswerCount)"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>{{$t("_common.Enter")}}</span>
                          </v-tooltip>
                        </v-card-text>
                      </v-container>
                    </template>
                  </v-card-text>
                </v-window-item>
              </template>
              <!-- WINDOW 2: FINISH EXAM -->
              <template style="margin-top: 5%">
                <v-window-item
                  v-if="questions.length > 0 && step >= 1"
                  :key="`window-${examId}-${maxStep}`"
                >
                  <v-card-text class="pa-5 mt-5 mb-1 text-center font-semibold" ><h5>{{ $t("_exam_list.Exam_text6") }} </h5></v-card-text>
                </v-window-item>
              </template>

            </v-window>


            <!-- BOTTOM BUTTONS -->

          </div>
        </v-col>
        <v-col cols="1" align-self="center">
          <v-row justify="start" v-if="questions.length > 0">
            <v-btn
              v-if="questions.length > 0 && step < maxStep-1"
              :disabled="step === maxStep+1 || !continueFlag"
              color="gray"
              rounded
              text
              @click="step++"
              style="position:absolute; top: 120px"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn v-else color="light-green" depressed rounded @click="finishExam" class= "font-semibold mt-5"><h6>{{$t("_common.Finish")}}</h6></v-btn>
          </v-row>
        </v-col>
        <!-- WINDOW 1: EXAM VIEW -->

      </v-row>
    </v-flex>
  </v-card>
</template>

<script>
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

  export default {
    data: () => ({
      step: 0,
      startingWindow: 0,
      examDetails: null,
      continueFlag: true,
      examFinished: false,
      maxStep: 1,
      treeSheetDataModel: [],
      treeSheetData: [],
      answerRadios: null,
      questions: [],
      intervalId: 0,
      examId: 0,
      timeLeft: 0,
      examDuration: 0,
      radioButtonModel: [],
      startTime: [],
      disableTree: false,
      timeKeepingTable: [],
      examEndIcon: "!",
      editor: ClassicEditor,

      currentWindow: {
        id: 0,
        date: 0
      },
      editorConfig: {
        placeholder: "asd"
        //plugins: [
        //  SimpleUploadAdapter
        //],
        //simpleUpload: {
        //  uploadUrl: this.$goc.ENUMS.API.Images,
        //  headers: {
        //
        //  }
        //}
      },
      isExamStarted: false,
    }),
    props: {
      exam: Number,
      type: Number,
      default: 0
    },
    computed: {
      fancyTimeFormat() {
        // Hours, minutes and seconds
        let hrs = ~~(this.timeLeft / 3600);
        let mins = ~~((this.timeLeft % 3600) / 60);
        let secs = ~~this.timeLeft % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
          ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
      }
    },
    methods: {
      getChoice(text) {
        if (text === 'True' || text === 'False') {
          return this.$t(`_add_questions.${text}`);
        }
        return text;
      },
      init() {
        this.startingWindow = true;
        this.examId = this.exam;
        this.startExam(this.examId, this.type);
        this.countdown()
      },
      addAnswerInput(step, questionType, maxLen) {
        if (this.radioButtonModel[step].length < maxLen)
          this.radioButtonModel[step].push({"Key": null, "Value": null});
      },
      initMatchingChoice(step, index) {
        if (step == index && (this.radioButtonModel[step] == undefined || this.radioButtonModel[step] == '') && typeof (this.radioButtonModel[step]) != 'object')
          this.radioButtonModel[step] = [];
      },
      deleteAnswer(answer, step) {
        if (answer != undefined) {
          var pos = this.radioButtonModel[step].indexOf(answer);
          this.radioButtonModel[step].splice(pos, 1);
          //document.getElementById("choice" + pos).focus();
        }
      },
      fancyTime(time) {
        let hrs = ~~(time / 3600);
        let mins = ~~((time % 3600) / 60);
        let secs = ~~time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
          ret += `${hrs}` + this.$t("_exam_management.Hours")
        }
        if (mins > 0) {
          ret += ` ${mins}` + this.$t("_exam_management.Minutes")
        }
        if (secs > 0) {
          ret += ` ${secs}` + this.$t("_exam_management.Seconds")
        }
        //
        // ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        // ret += "" + secs;
        return ret;
      },
      getSheetDetails() {
        let sheetId = this.exam;
        this.$goc.console.log("Sheet: " + sheetId)
        this.$goc.request.get(`${this.$enums.API.QuestionSheet}/${sheetId}`, (response) => {
          this.examDetails = response.Result.Sheets;
        });
      },
      copyevent() {
        this.$goc.console.log("copied")
      },

      startExam(exam, type) {
        if (exam) {
          this.$goc.request.post(this.$enums.API.Exam_Start, {
              UserId: JSON.parse(localStorage.getItem("userInfo")).Uid,
              QuestionSheetId: exam,
              ExamType: type ? type : this.$enums.EXAM_TYPE.EXAM
            }
            , {
              then: (response) => {
                this.questions = response.Result.Questions;
                console.log(this.questions);
                this.questions.forEach(question => {
                  if (question.Type == this.$goc.ENUMS.QUESTION_TYPE.Matching) {
                    question['MatchingKeys'] = [];
                    question['MatchingValues'] = [];
                    //question['MatchingKeys'] = [{ text: "", val: null}];
                    //question['MatchingValues'] = [{ text: "", val: null}];
                    question.MatchingChoices.MatchingChoiceDtos.forEach(keyItem => {
                      if(keyItem.Key || keyItem.ImageKeyUrl) question['MatchingKeys'].push({text: keyItem.Key, img:keyItem.ImageKeyUrl});
                    });
                    question.MatchingChoices.MatchingChoiceDtos.forEach(valueItem => {
                     if(valueItem.Value || valueItem.ImageValueUrl)   question['MatchingValues'].push({text: valueItem.Value, img: valueItem.ImageValueUrl});
                    });
                  }
                });
                console.log(this.questions)
                this.maxStep = this.questions.length + 1;
                this.examId = response.Result.ExamId;
                this.radioButtonModel = new Array(this.questions.length);
                this.timeKeepingTable = new Array(this.questions.length);
                this.currentWindow.date = new Date();
                this.currentWindow.id = 0;
                this.disableTree = true;
                this.timeLeft = parseInt(response.Result.Duration);
                this.isExamStarted = true;
                this.$goc.set('examStarted', true);
              },
              final: () => {
                this.examDuration = this.timeLeft;
                this.step = 0;
                this.timeKeepingTable = new Array(this.questions.length);
                for (let index = 0; index < this.timeKeepingTable.length; index++) {
                  this.timeKeepingTable[index] = 0;
                }
              }
            });
        }
      },
      finishExam() {
        if(!this.isExamStarted)
          return;

        var answers = [];

        for (let i = 0; i < this.questions.length; i++) {
          if ((this.questions[i].Type == this.$goc.ENUMS.QUESTION_TYPE.MultipleChoice || this.questions[i].Type == this.$goc.ENUMS.QUESTION_TYPE.TrueFalse) && this.questions[i].Choices[this.radioButtonModel[i]] != null) {
            answers.push({
              questionId: this.questions[i].Id,
              choiceId: this.questions[i].Choices[this.radioButtonModel[i]].Id,
              answerTime: {Seconds: this.timeKeepingTable[i] / 1000, Nanos: 0}
            });
          } else if (this.questions[i].Type == this.$goc.ENUMS.QUESTION_TYPE.OpenEnded) {
            answers.push({
              questionId: this.questions[i].Id,
              text: this.radioButtonModel[i],
              answerTime: {Seconds: this.timeKeepingTable[i] / 1000, Nanos: 0}
            });
          } else if (this.questions[i].Type == this.$goc.ENUMS.QUESTION_TYPE.Matching) {
            answers.push({
              questionId: this.questions[i].Id,
              matchingChoice: this.radioButtonModel[i],
              answerTime: {Seconds: this.timeKeepingTable[i] / 1000, Nanos: 0}
            });
          } else {
            answers.push({
              questionId: this.questions[i].Id,
              choiceId: 0,
              answerTime: {Seconds: this.timeKeepingTable[i] / 1000, Nanos: 0}
            });
          }
        }
        this.$goc.request.post(this.$enums.API.Exam_Finish, {
            ExamId: this.examId,
            Answers: answers,
            TotalTime: {Seconds: this.examDuration - this.timeLeft, Nanos: 0}
          },
          {
            final: () => {
              this.step = 0;
              this.questions = [];
              this.examFinished = true;
              this.startingWindow = 0;
              this.$goc.set('examStarted', false);
            }

          });
      },
      onSelectedSheet(item) {
        this.startExam(item.Id);
      },
      dialogNotify(message, notifyColor) {
        this.$vs.notify({
          title: "Error",
          text: message,
          color: notifyColor
        });
      },
      answer(item) {
        this.$goc.console.log(item);
      },
      timekeeper() {
      },
      countdown() {
        const _self = this;
        this.intervalId = setInterval(function () {
          if (_self.timeLeft > 0)
            _self.timeLeft--;
        }, 1000);

      },
      
    checkURL(url) {
        return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    },
    },
    watch: {
      step: function (newStep, oldStep) {
        if (this.timeKeepingTable[this.currentWindow.id] != null) {
          this.timeKeepingTable[this.currentWindow.id] += new Date().getTime() - this.currentWindow.date.getTime();
          this.currentWindow.id = newStep;
          this.currentWindow.date = new Date();
        } else {
          this.timeKeepingTable[this.currentWindow.id] = 0;
          this.timeKeepingTable[oldStep] += new Date().getTime() - this.currentWindow.date.getTime();
          this.currentWindow.id = newStep;
          this.currentWindow.date = new Date();
        }
      },
      timeLeft: function (newTime) {
        if (newTime == 0) {
          clearInterval(this.intervalId);
          this.finishExam();
        }
      }
    },
    created: function () {
      this.getSheetDetails();
    },
    mounted() {
      this.$goc.setModule("ExamComponent", this);
      window.onbeforeunload = function (choice) {
        if (goc.get('examStarted')) {
          return "Do you really want to leave exam application?";
        } else {
          return;
        }
      };
    }
  };
</script>

<style lang="scss">
.imageradio {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #e4e4e4;
  border-radius: 3px;

  .selected {
    border: 2px solid pink;
  }

  img {
    margin-left: 2.5rem;
  }

  &.withIamge {
    .v-input--selection-controls__input {
      position: absolute;
      top: 50%;
    }

    label {
      margin-left: 2.5rem;
    }
  }
}

.blockquote {
    background: #f6f7f9;
    padding: 20px;
    border-radius: 20px;
    margin-top: 1rem;
    font-weight: 500;
}
</style>

