<template>

    <v-card>

      <!-- HEADER TOOLBAR -->
      <v-toolbar class="page-primary-card-header">
        <v-list-item dark>
          <v-list-item-avatar color="primary lighten-2">
            <v-icon>{{card_header_props.header.icon}}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
            <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-btn
          @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
          icon
          dark
        >
          <v-icon>info</v-icon>
        </v-btn>

        <v-dialog v-model="card_header_props.helper.page_helper" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>How can I use this form?</v-card-title>

            <v-card-text>In this page, you can attend exams and solve questions.</v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <!-- HEADER TOOLBAR -->

      <v-container>
        <v-row row wrap>
          <v-col cols="12" sm="12">
            <p>Please select an exam...</p>
            <v-select solo :items="items" return-object item-text="Title" item-key="Id" v-model="selectedExam" no-data-text="No exam assigned" @change="logError(selectedExam)"></v-select>
          </v-col>
          <v-col cols="12" sm="12">
            <exam-component v-if="selectedExam !== null" :exam="selectedExam.Id"></exam-component>
          </v-col>
        </v-row>
      </v-container>

    </v-card>

</template>

<script>
  import ExamComponent from "./ExamComponent";
  // import request from "./common/HttpRequests.js";
  require("../../assets/css/neo.css");
  export default {
    data() {
      return {
        selectedExam: null,
        items: [],
        examId: 2,
        card_header_props: {
          header: {
            headLine: "Select an exam",
            subTitle: "You can select and start an exam.",
            icon: "watch_later"
          },
          helper: {
            absolute: true,
            opacity: 0.8,
            overlay: false,
            page_helper: false
          }
        }
      }
    },
    methods: {
      logError(err) {
        this.$goc.console.log(err)
      },
      getAssignedExams() {
        let uid = JSON.parse(localStorage.getItem('userInfo')).Uid;

        this.$goc.request.get(this.$enums.API.Exam_User +`${uid}` , response => {
          this.$goc.console.log(response)
          this.items = response.Result.Exams;
        });
        //
        // request.HttpGet("/api/Lexicon/Exam/User/" + uid)
        // .then((response) => {
        //   this.$goc.console.log(response)
        //   this.items = response.data.Exams;
        // })
      },
    },
    components: {
      ExamComponent
    },
    mounted: function () {
      this.getAssignedExams();
    },
  }
</script>

<style scoped>

</style>
